import React, { Component } from 'react';
import { func, shape } from 'prop-types';
import { injectIntl } from '../../util/reactIntl';
import FilterLocationPopup from '../../components/FilterLocationPopup/FilterLocationPopup';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import TopbarSearchLocationForm from '../../forms/TopbarSearchLocationForm/TopbarSearchLocationForm';
import css from './LocationFilter.module.css';
import config from '../../config';

class LocationFilter extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const { urlQueryParams } = this.props;
    const { search, selectedPlace } = values.location;
    const { history } = this.props;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...urlQueryParams,
      ...originMaybe,
      address: search,
      bounds,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      label,
      initialValues,
      initialSearchFormValues,
      showAsPopup,
      queryParamNames,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const getQueryParamName = queryParamNames => {
      return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
    };

    const queryParamName = getQueryParamName(queryParamNames);
    const hasInitialValues = !!initialValues && !!initialValues[queryParamName];
    const initialValue = initialValues && initialValues[queryParamName] ? initialValues[queryParamName] : '';
    return showAsPopup ? (
      <FilterLocationPopup
        className={classes}
        label={label}
        id={`${id}.popup`}
        isSelected={hasInitialValues}
      >
        <TopbarSearchLocationForm
          className={css.searchLink}
          desktopInputRoot={css.topbarSearchWithLeftPadding}
          onSubmit={this.handleSubmit}
          initialValues={initialSearchFormValues}
          value={initialValue}
        />
      </FilterLocationPopup>
    ) : (
      <>
        <div className={classes}>
          <div className={css.filterLabel}>
            <button type="button" className={css.labelButton} onClick={this.toggleIsOpen}>
              <span className={css.filterLabel}>{label}</span>
            </button>
          </div>
          <TopbarSearchLocationForm
            className={css.searchLink}
            desktopInputRoot={css.topbarSearchWithLeftPadding}
            onSubmit={this.handleSubmit}
            initialValues={initialSearchFormValues}
            value={initialValue}
          />
        </div>
      </>
    )
  }
}

LocationFilter.propTypes = {
  history: shape({
    push: func.isRequired,
  }).isRequired,
};
export default injectIntl(LocationFilter);
